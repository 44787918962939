<script>

export default {
  props: {
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    id: {
      type: String,
      required: false
    },
  },
  methods: {},
}
</script>

<template>
  <label :for="id" class="fg-black">
    <slot></slot>
    {{ this.required ? " *" : "" }}</label>
</template>