import Api from "@/services/api";
import {removeEmptyParams, downloadData} from "@utils";

export default {
    findAll(filter) {
        return Api().get("/bilancrises", {params: removeEmptyParams(filter)}).then(res => {
            res.data.forEach(e => this.formatRes(e));
            return res.data;
        });
    },
    findAllCurrent(filter) {
        return Api().get("/bilancrises/current", {params: removeEmptyParams(filter)}).then(res => {
            res.data.forEach(e => this.formatRes(e));
            return res.data;
        });
    },
    findAllByYear(year) {
        return Api().get("/bilancrises/year/" + year).then(res => {
            res.data.forEach(e => this.formatRes(e));
            return res.data;
        });
    },
    findSyntheseByYear(year) {
        return Api().get("/bilancrise/synthese/" + year).then(res => {
            res.data.forEach(e => this.formatRes(e));
            return res.data;
        });
    },
    findSyntheseByCurrentYear() {
        return Api().get("/bilancrise/synthese").then(res => {
            res.data.forEach(e => this.formatRes(e));
            return res.data;
        });
    },
    findById(id) {
        return Api().get("/bilancrise/" + id).then(res => this.formatRes(res.data));
    },
    findByCeiIdAndCrisePeriodId(ceiId, crisePeriodId) {
        return Api().get("/bilancrise/cei/" + ceiId + "/criseperiod/" + crisePeriodId).then(res => this.formatRes(res.data));
    },
    findCurrentYear() {
        return Api().get("/bilancrise/currentyear").then(res => res.data);
    },
    create(bilanCrise) {
        return Api().post("/bilancrise", bilanCrise).then(res => this.formatRes(res.data));
    },
    update(bilanCrise) {
        return Api().put("/bilancrise/" + bilanCrise.id, bilanCrise).then(res => this.formatRes(res.data));
    },
    delete(bilanCrise) {
        return Api().delete("/bilancrise/" + bilanCrise.id).then(() => bilanCrise);
    },
    formatRes(e) {
        return e;
    },
    download(year) {
        return Api().get("/bilancrise/download/" + year, {responseType: "blob"}).then(res => downloadData(res));
    }
}