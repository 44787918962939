export default {
    data() {
        return {
            ref: null,
        }
    },
    created() {
        this.ref = "ref" + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    },
    methods: {
        getRef() {
            return this.$refs[this.ref];
        }
    }
}
