import {goBack} from "@utils";

export default {
    methods: {
        goBack() {
            goBack(this.$router)
        },
    }

}
